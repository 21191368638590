import { useState } from "react";
import type { ChangeEvent, FC, MouseEvent } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Calendar from "react-calendar";
import PropTypes from "prop-types";
import ExcelJS from "exceljs";
import ClearIcon from "@material-ui/icons/Clear";

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "../../../icons/Search";
import type { Customer } from "../../../types/customer";
import Scrollbar from "../../Scrollbar";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Gender, SkeletonData } from "src/constants";
import useAuth from "src/hooks/useAuth";
import { GET_PLATFORM_BY_ID, PLATFORM_ATHLETES } from "src/graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import countries from "../account/countries";
import useDebounce from "src/utils/debounce";
import { UPDATE_USER } from "src/graphql/mutations";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  logosContainer: {
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  logoItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 120,
    width: "100%",
    borderRadius: 16,
    backgroundColor: "#FBFBFB",
  },
  label: {
    color: "#1B3861",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    minWidth: "80px",
  },
  selectInput: {
    "& .MuiInputBase-root": {
      height: "36px!important",
    },
  },
  textfield: {
    flexShrink: 0,
    borderRadius: "8px",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      flexShrink: 0,
      borderRadius: "8px",
    },
  },
  labelfield: {
    color: "#1B3861",
    fontSize: "12px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    opacity: 1,
  },
  title: {
    color: "#0A2540",
    fontFamily: "Eudoxus Sans",
    fontStyle: "normal",
    lineHeight: "23.28px",
  },
  popoveer: {
    padding: "24px 30px 24px 24px",
    position: "absolute",
    top: 170,
    right: "30px",
    zIndex: 11,
    borderRadius: "6px",
    border: "1px solid #F6F7F9",
    background: "#FFF",
    boxShadow: "20px 20px 52px 0px rgba(0, 0, 0, 0.10)",
  },
  button: {
    background:
      "linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)",
  },
}));

const PlatformUserTable: FC<any> = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const {
    members,
    count,
    loading,
    setGender,
    gender,
    setSports,
    sports,
    setCountry,
    setClub,
    club,
    country,
    setListingDateRange,
    listingDateRange,
    dateRangeLabel,
    query,
    limit,
    page,
    total,
    setQuery,
    setPage,
    setLimit,
    ...other
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const token = Cookies.get("token");

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSelectAllCustomers = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedCustomers(
      event.target.checked ? members.map((customer) => customer.member.id) : []
    );
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const enableBulkActions = selectedCustomers?.length > 0;
  const selectedSomeCustomers =
    selectedCustomers?.length > 0 &&
    selectedCustomers?.length < members?.length;
  const selectedAllCustomers = selectedCustomers?.length === members?.length;
  const navigate = useNavigate();
  const auth = useAuth();
  const [openTournamentFilters, setOpenTournamentFilters] = useState(false);
  const classes = useStyles();
  const [clubsData, setClubsData] = useState<any>([]);
  const [sportsData, setSportsData] = useState<any>([]);
  const [isCalendar, setIsCalendar] = useState(false);
  const [thirdParty, setthirdParty] = useState([]);

  const downloadExcel = (CSVType) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    let headers;
    if (CSVType === "userList") {
      headers = ["Name", "Surname", "Email", "Phone number", "Country", "Role"];

      worksheet.addRow(headers);

      members.forEach((user, index) => {
        const row = [
          user?.user?.name || "Not Added",
          user?.user?.surname || "Not Added",
          user?.user?.email || "Not Added",
          user?.user?.phone || "Not Added",
          user?.user?.nation || "Not Added",
          typeof user?.user.role === "object"
            ? user?.user.role.map((item) => item?.role?.name)
            : user?.user.role,
        ];

        const excelRow = worksheet.addRow(row);

        excelRow.eachCell((cell) => {
          cell.alignment = { horizontal: "left" };
        });
      });
    } else {
      headers = ["Email"];

      worksheet.addRow(headers);

      members.forEach((user, index) => {
        const row = [user?.user?.email || "Not Added"];

        const excelRow = worksheet.addRow(row);

        excelRow.eachCell((cell) => {
          cell.alignment = { horizontal: "left" };
        });
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        CSVType === "userList" ? "users_list.xlsx" : "user_emails.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  const { loading: loader1 } = useQuery(GET_PLATFORM_BY_ID, {
    variables: {
      id: auth?.platformId,
    },
    onCompleted: (data) => {
      setthirdParty(data?.platform?.thirdPartyLicenses);
      setClubsData(
        data?.platform?.club
          ?.filter((item) => item?.status === "Active")
          ?.map((item) => ({
            id: item?.id,
            title: item?.clubName,
          }))
      );
      setSportsData(
        data?.platform?.sports?.map((item) => {
          return {
            id: item?.sport?.id,
            title: item?.sport?.title,
          };
        })
      );
    },
  });
  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const onChangeListingDateRange = (value: Value) => {
    if (Array.isArray(value)) {
      setListingDateRange(value);
    }
  };

  const countryArray = countries.map((item) => item?.text);

  const [update] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: PLATFORM_ATHLETES,
        variables: {
          page: page + 1,
          limit: limit,
          filter: {
            email: query,
          },
          platform: auth.platformId,
        },
      },
    ],
  });
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [fieldValue, setFieldValue] = useState("");

  const handleValueChange = (event) => {
    setFieldValue(event.target.value);
    setId(event.target.id);
    setName(event.target.name);
  };

  function getVariables(name, id, fieldValue) {
    let variables;
    switch (name) {
      case "ipinId":
        variables = {
          id: parseInt(id),
          ipinId: fieldValue,
        };
        break;
      case "duprId":
        variables = {
          id: parseInt(id),
          duprId: fieldValue,
        };
        break;
      case "teId":
        variables = {
          id: parseInt(id),
          teId: fieldValue,
        };
        break;
      case "ltsU10Id":
        variables = {
          id: parseInt(id),
          ltsU10Id: fieldValue,
        };
        break;
      case "atpId":
        variables = {
          id: parseInt(id),
          atpId: fieldValue,
        };
        break;
      case "wtaId":
        variables = {
          id: parseInt(id),
          wtaId: fieldValue,
        };
        break;
      case "fideId":
        variables = {
          id: parseInt(id),
          fideId: fieldValue,
        };
        break;

      default:
        break;
    }
    return variables;
  }

  useDebounce(
    () => {
      if (id) {
        const variables = getVariables(name, id, fieldValue);

        toast.promise(
          update({
            variables,
          }),
          {
            loading: "updating",
            success: () => {
              return "User license updated successfully";
            },
            error: (err) => {
              return err.message;
            },
          }
        );
      }
    },
    [id, name, fieldValue],
    500
  );

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 2,
          m: -1,
          pt: 2,
          pb: 5,
          px: 1,
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "600" }} variant="h2">
            {t("Users")}
          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 2,
            m: -1,
            px: 1,
          }}
        >
          <Box
            sx={{
              maxWidth: "100%",
              width: 253,
              display: {
                md: "block",
                xs: "none",
              },
            }}
          >
            <TextField
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t("Search User")}
              value={query}
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: { sm: "none", xs: "flex" },
              justifyContent: "space-between",
            }}
          >
            {location.pathname === "/dashboard/platform-users" && (
              <Box
                sx={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  sx={{ height: "36px", textTransform: "uppercase" }}
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation(),
                      setOpenTournamentFilters(!openTournamentFilters);
                  }}
                >
                  {t("Filter users")}
                </Button>
              </Box>
            )}
            {auth?.user?.role !== "federation" ? (
              <Box>
                <Button
                  size="small"
                  color="primary"
                  startIcon={
                    <ReactSVG
                      src="/images/addw.svg"
                      style={{
                        marginTop: "4px",
                        paddingLeft: "5px",
                      }}
                    />
                  }
                  sx={{ height: "36px", textTransform: "uppercase", mt: -0.3 }}
                  variant="contained"
                  onClick={() =>
                    location.pathname === "/dashboard/platform-users"
                      ? navigate("/dashboard/platform-users/new")
                      : navigate("/dashboard/users/new")
                  }
                >
                  {t("ADD NEW USER")}
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
          {location.pathname === "/dashboard/platform-users" && (
            <Button
              size="small"
              color="primary"
              startIcon={<GetAppIcon />}
              sx={{ height: "36px", textTransform: "uppercase", mt: -0.3 }}
              variant="contained"
              onClick={() => downloadExcel("userList")}
            >
              {t("Export users")}
            </Button>
          )}
          {location.pathname === "/dashboard/platform-users" && (
            <Button
              size="small"
              color="primary"
              startIcon={<GetAppIcon />}
              sx={{ height: "36px", textTransform: "uppercase", mt: -0.3 }}
              variant="contained"
              onClick={() => downloadExcel("userEmail")}
            >
              {t("Export user emails")}
            </Button>
          )}
          {auth?.user?.role !== "federation" ? (
            <Box sx={{ display: { sm: "block", xs: "none" } }}>
              <Button
                size="small"
                color="primary"
                startIcon={
                  <ReactSVG
                    src="/images/addw.svg"
                    style={{
                      marginTop: "4px",
                      paddingLeft: "5px",
                    }}
                  />
                }
                sx={{ height: "36px", textTransform: "uppercase", mt: -0.3 }}
                variant="contained"
                onClick={() =>
                  location.pathname === "/dashboard/platform-users"
                    ? navigate("/dashboard/platform-users/new")
                    : navigate("/dashboard/users/new")
                }
              >
                {t("ADD NEW USER")}
              </Button>
            </Box>
          ) : (
            ""
          )}
          {location.pathname === "/dashboard/platform-users" && (
            <Box
              sx={{
                paddingTop: "5px",
                cursor: "pointer",
                position: "relative",
                display: { sm: "block", xs: "none" },
              }}
              onClick={(e) => {
                e.stopPropagation(),
                  setOpenTournamentFilters(!openTournamentFilters);
              }}
            >
              <img
                src="/images/tourFilters.svg"
                style={{ width: "26px", height: "26px" }}
              />
            </Box>
          )}

          {openTournamentFilters && (
            <Box
              sx={{
                position: "fixed",
                left: "0px",
                right: "0px",
                top: "0px",
                zIndex: "10",
                height: "100vh",
              }}
              onClick={(e) => {
                e.stopPropagation(),
                  setOpenTournamentFilters(!openTournamentFilters);
              }}
            />
          )}
          <Grid
            container
            lg={3}
            sm={6}
            xs={10}
            spacing={1}
            sx={{
              display: openTournamentFilters ? "flex" : "none",

              mt: {
                lg: 0,
                xs: 0,
              },
              justifyContent: "center",
            }}
            className={openTournamentFilters ? classes.popoveer : ""}
          >
            {/* @ts-ignore */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="ageGroup"
                placeholder={t("Age group")}
                onChange={(e) => setGender(e.target.value)}
                select
                SelectProps={{
                  native: true,
                  IconComponent: () => (
                    <img
                      src="/images/Chevron.svg"
                      style={{ padding: "10px" }}
                    />
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFEFF0",
                    "& fieldset": {
                      borderWidth: "0px",
                    },
                    "&:hover fieldset": {
                      borderWidth: "0px",
                      transform: "none",
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "0px",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "9px 14px",
                    paddingRight: "0px!important",
                  },
                }}
                value={gender}
                className={classes.textfield}
              >
                <option value="">Gender</option>
                {Gender?.map((group, index) => (
                  <option key={index} value={group?.value}>
                    {group?.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={sportsData?.length > 0 ? sportsData : []}
                // @ts-ignore
                getOptionLabel={(option) => option?.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: React.ChangeEvent<{}>, value: any) => {
                  setSports(value);
                }}
                value={sports?.length > 0 ? sports : []}
                popupIcon={
                  <img src="/images/Chevron.svg" style={{ padding: "6px" }} />
                }
                sx={{
                  background: "#fff",
                  zIndex: 199,
                  position: "relative",
                  "& .MuiOutlinedInput-root": {
                    minHeight: "39px",
                    border: "1px solid #EFEFF0",
                    "& fieldset": {
                      borderWidth: "0px",
                    },
                    "&:hover fieldset": {
                      borderWidth: "0px",
                      transform: "none",
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "0px",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.4375em",
                  },
                }}
                className={classes.textfield}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    name="country"
                    placeholder={t("Sport")}
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.labelfield,
                    }}
                    className={classes.textfield}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={countryArray}
                onChange={(event: React.ChangeEvent<{}>, value: any) => {
                  setCountry(value);
                }}
                value={country.length > 0 ? country : []}
                popupIcon={
                  <img src="/images/Chevron.svg" style={{ padding: "6px" }} />
                }
                sx={{
                  background: "#fff",
                  zIndex: 199,
                  position: "relative",
                  "& .MuiOutlinedInput-root": {
                    minHeight: "39px",
                    border: "1px solid #EFEFF0",
                    "& fieldset": {
                      borderWidth: "0px",
                    },
                    "&:hover fieldset": {
                      borderWidth: "0px",
                      transform: "none",
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "0px",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.4375em",
                  },
                }}
                className={classes.textfield}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    name="country"
                    placeholder={t("Country")}
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.labelfield,
                    }}
                    className={classes.textfield}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={clubsData?.length > 0 ? clubsData : []}
                // @ts-ignore
                getOptionLabel={(option) => option?.title}
                onChange={(event: React.ChangeEvent<{}>, value: any) => {
                  setClub(value);
                }}
                value={club.length > 0 ? club : []}
                popupIcon={
                  <img src="/images/Chevron.svg" style={{ padding: "6px" }} />
                }
                sx={{
                  background: "#fff",
                  zIndex: 199,
                  position: "relative",
                  "& .MuiOutlinedInput-root": {
                    minHeight: "39px",
                    border: "1px solid #EFEFF0",
                    "& fieldset": {
                      borderWidth: "0px",
                    },
                    "&:hover fieldset": {
                      borderWidth: "0px",
                      transform: "none",
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "0px",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.4375em",
                  },
                }}
                className={classes.textfield}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    name="country"
                    placeholder={t("Club")}
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.labelfield,
                    }}
                    className={classes.textfield}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    minHeight: "39px",
                    border: "1px solid #EFEFF0",
                    width: "100%",
                    borderRadius: "8px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "#5D6B83",
                    px: "15px",
                  }}
                  onClick={() => setIsCalendar(!isCalendar)}
                >
                  {dateRangeLabel ?? (
                    <Typography>{t("Date of birth")}</Typography>
                  )}
                </Box>
                <Box sx={{ position: "absolute", right: "14px", top: "10px" }}>
                  {listingDateRange[0] !== null &&
                  listingDateRange[1] !== null ? (
                    <ClearIcon
                      sx={{
                        cursor: "pointer",
                        zIndex: "99999999",
                        width: "20px",
                      }}
                      onClick={() => {
                        setListingDateRange([new Date(), null]);
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{
                        width: "20px",
                        color: "#8e959b",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsCalendar(!isCalendar)}
                    />
                  )}
                </Box>

                {isCalendar && (
                  <Box>
                    <Box
                      sx={{
                        position: "fixed",
                        left: "0px",
                        right: "0px",
                        top: "0px",
                        height: "100vh",
                        zIndex: "9999999",
                      }}
                      onClick={() => setIsCalendar(false)}
                    />
                    <Box
                      sx={{
                        width: "300px",
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <Box className="dashboard-date-picker">
                        <Box className="calendar-custom-wrapper">
                          <Calendar
                            selectRange
                            // maxDate={new Date()}
                            onChange={onChangeListingDateRange}
                            value={listingDateRange}
                            formatShortWeekday={(locale, date) =>
                              ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][
                                date.getDay()
                              ]
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              display: {
                md: "none",
                xs: "block",
              },
            }}
          >
            <TextField
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t("Search User")}
              value={query}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Card
        {...other}
        sx={{
          boxShadow: "none",
          borderTop: "1px solid #e0e0e0",
          borderRadius: "0px",
        }}
      >
        {enableBulkActions && (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                backgroundColor: "background.paper",
                mt: "6px",
                position: "absolute",
                px: "4px",
                width: "100%",
                zIndex: 2,
              }}
            >
              <Checkbox
                checked={selectedAllCustomers}
                color="primary"
                indeterminate={selectedSomeCustomers}
                onChange={handleSelectAllCustomers}
              />
              <Button color="primary" sx={{ ml: 2 }} variant="outlined">
                Delete
              </Button>
              <Button color="primary" sx={{ ml: 2 }} variant="outlined">
                Edit
              </Button>
            </Box>
          </Box>
        )}
        {members?.length > 0 ? (
          <Box>
            <Scrollbar>
              <Box sx={{ minWidth: 700 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Name")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Surname")}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Email")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Avatar")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Phone Number")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Country")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("City")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("Role")}
                        </Typography>
                      </TableCell>
                      {thirdParty?.some((item) => item?.id === 1) && (
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "rgba(27, 56, 97, 0.6)",
                              fontWeight: 500,
                              whiteSpace: "pre",
                            }}
                          >
                            {t("IPIN License")}
                          </Typography>
                        </TableCell>
                      )}
                      {thirdParty?.some((item) => item?.id === 4) && (
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "rgba(27, 56, 97, 0.6)",
                              fontWeight: 500,
                              whiteSpace: "pre",
                            }}
                          >
                            {t("DUPR License")}
                          </Typography>
                        </TableCell>
                      )}
                      {/* 
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("TE License")}
                        </Typography>
                      </TableCell> */}

                      {/* <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(27, 56, 97, 0.6)",
                            fontWeight: 500,
                            whiteSpace: "pre",
                          }}
                        >
                          {t("LTS U10 License")}
                        </Typography>
                      </TableCell> */}
                      {thirdParty?.some((item) => item?.id === 2) && (
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "rgba(27, 56, 97, 0.6)",
                              fontWeight: 500,
                              whiteSpace: "pre",
                            }}
                          >
                            {t("ATP points")}
                          </Typography>
                        </TableCell>
                      )}
                      {thirdParty?.some((item) => item?.id === 3) && (
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "rgba(27, 56, 97, 0.6)",
                              fontWeight: 500,
                              whiteSpace: "pre",
                            }}
                          >
                            {t("WTA points")}
                          </Typography>
                        </TableCell>
                      )}
                      {thirdParty?.some((item) => item?.id === 5) && (
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "rgba(27, 56, 97, 0.6)",
                              fontWeight: 500,
                              whiteSpace: "pre",
                            }}
                          >
                            {t("FIDE License")}
                          </Typography>
                        </TableCell>
                      )}

                      {/* {auth?.user?.role !== "federation" ? ( */}
                      <TableCell align="right"></TableCell>
                      {/* ) : (
                        ""
                      )} */}
                    </TableRow>
                  </TableHead>
                  {!loading ? (
                    // members?.length == 0 ? (
                    <TableBody>
                      {members?.map((item: any, index) => {
                        const isCustomerSelected = selectedCustomers.includes(
                          item?.id
                        );

                        return (
                          <TableRow
                            hover
                            key={index}
                            selected={isCustomerSelected}
                            sx={{
                              background:
                                index % 2 === 0 && "rgba(0, 0, 0, 0.04)",
                            }}
                          >
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box
                                  sx={{ ml: 1, cursor: "pointer" }}
                                  onClick={() =>
                                    window.open(
                                      `${process.env.REACT_APP_CLIENT_URL}/athlete/${item?.id}/${item?.name}-${item?.surname}?token=${token}`
                                    )
                                  }
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#193660",
                                      fontWeight: 500,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {item?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#193660",
                                  fontWeight: 500,
                                  textTransform: "uppercase",
                                }}
                              >
                                {item?.surname}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#193660", fontWeight: 500 }}
                                >
                                  {`${item?.email}`}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Avatar src={item?.avatar} />
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "#193660", fontWeight: 500 }}
                              >
                                {`${item?.phone}`}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "#193660", fontWeight: 500 }}
                              >
                                {`${item?.nation}`}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "#193660", fontWeight: 500 }}
                              >
                                {item?.city ?? "NA"}
                              </Typography>
                            </TableCell>
                            {location.pathname ===
                            "/dashboard/platform-users" ? (
                              <TableCell>
                                <Typography
                                  key={index}
                                  variant="body1"
                                  sx={{
                                    color: "#193660",
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.role}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#193660",
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {`${
                                    item?.user?.role === "federation"
                                      ? "Organization"
                                      : item?.user?.role
                                  }`}
                                </Typography>
                              </TableCell>
                            )}
                            {thirdParty?.some((item) => item?.id === 1) && (
                              <TableCell>
                                <input
                                  type="text"
                                  key={item?.id}
                                  defaultValue={item?.ipinId}
                                  onChange={handleValueChange}
                                  name="ipinId"
                                  id={item?.user?.id}
                                  style={{ border: "none", width: "60px" }}
                                />
                              </TableCell>
                            )}
                            {thirdParty?.some((item) => item?.id === 4) && (
                              <TableCell>
                                <input
                                  type="text"
                                  key={item?.id}
                                  defaultValue={item?.duprId}
                                  onChange={handleValueChange}
                                  name="duprId"
                                  id={item?.user?.id}
                                  style={{ border: "none", width: "60px" }}
                                />
                              </TableCell>
                            )}
                            {/* <TableCell>
                              <input
                                type="text"
                                key={item?.user?.id}
                                defaultValue={item?.user?.teId}
                                onChange={handleValueChange}
                                name="teId"
                                id={item?.user?.id}
                                style={{ border: "none", width: "60px" }}
                              />
                            </TableCell> */}
                            {/* <TableCell>
                              <input
                                type="text"
                                key={item?.user?.id}
                                defaultValue={item?.user?.ltsU10Id}
                                onChange={handleValueChange}
                                name="ltsU10Id"
                                id={item?.user?.id}
                                style={{ border: "none", width: "60px" }}
                              />
                            </TableCell> */}
                            {thirdParty?.some((item) => item?.id === 2) && (
                              <TableCell>
                                <input
                                  type="text"
                                  key={item?.user?.id}
                                  defaultValue={item?.atpId}
                                  onChange={handleValueChange}
                                  name="atpId"
                                  id={item?.user?.id}
                                  style={{ border: "none", width: "60px" }}
                                />
                              </TableCell>
                            )}
                            {thirdParty?.some((item) => item?.id === 3) && (
                              <TableCell>
                                <input
                                  type="text"
                                  key={item?.id}
                                  defaultValue={item?.wtaId}
                                  onChange={handleValueChange}
                                  name="wtaId"
                                  id={item?.user?.id}
                                  style={{ border: "none", width: "60px" }}
                                />
                              </TableCell>
                            )}
                            {thirdParty?.some((item) => item?.id === 5) && (
                              <TableCell>
                                <input
                                  type="text"
                                  key={item?.user?.id}
                                  defaultValue={item?.user?.fideId}
                                  onChange={handleValueChange}
                                  name="fideId"
                                  id={item?.id}
                                  style={{ border: "none", width: "60px" }}
                                />
                              </TableCell>
                            )}

                            {/* {auth?.user?.role !== "federation" ? ( */}
                            <TableCell align="right">
                              <IconButton
                                component={RouterLink}
                                to={`edit/${item?.id}`}
                              >
                                <ReactSVG src="/images/pencil.svg" />
                              </IconButton>
                            </TableCell>
                            {/* ) : (
                              ""
                            )} */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    SkeletonData?.map((count, index) => (
                      <TableRow key={index}>
                        {SkeletonData?.map((countindex1) => (
                          <TableCell key={countindex1}>
                            <Box
                              sx={{
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Skeleton
                                variant="text"
                                width={120}
                                height={50}
                                animation="wave"
                                key={count}
                              />
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  )}
                </Table>
              </Box>
            </Scrollbar>
            {/* {count && ( */}
            <TablePagination
              component="div"
              count={total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
            {/* )} */}
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h2" sx={{ py: 10 }}>
              {t("There is no available users")}
            </Typography>
          </Box>
        )}
      </Card>
    </>
  );
};

PlatformUserTable.propTypes = {
  members: PropTypes.array.isRequired,
};

export default PlatformUserTable;
