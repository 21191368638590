import { useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Breadcrumbs, Grid, Link } from "@material-ui/core";
import gtm from "src/lib/gtm";
import { useQuery } from "@apollo/client";
import { PLATFORM_ATHLETES } from "src/graphql/queries";
import Loading from "src/components/Loading";
import useAuth from "src/hooks/useAuth";
import PlatformUserTable from "src/components/dashboard/users/platformUsersTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { rowPerPage } from "src/constants";
import useDebouncedTerm from "src/hooks/useDebouncedTerm";
import useFederationRedirect from "src/utils/redirectTodashboard";

const PlatformUsers: FC = () => {
  const auth = useAuth();
  const { checkAndRedirect } = useFederationRedirect();
  const { t } = useTranslation();

  const location = useLocation();
  const [page, setpage] = useState(0);
  const [limit, setLimit] = useState<number>(rowPerPage);
  const [query, setQuery] = useState<string>("");
  const [resultCount, setResultCount] = useState<number>();
  const [usersArray, setUsersArray] = useState<any>([]);
  const [gender, setGender] = useState<string>("");
  const [sports, setSports] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [club, setClub] = useState<any>([]);
  const [listingDateRange, setListingDateRange] = useState<any>([
    new Date(),
    null,
  ]);
  let fromAge = moment(listingDateRange[0]).format("YYYY-MM-DD");
  let toAge = moment(listingDateRange[1]).format("YYYY-MM-DD");

  const debouncedSearch = useDebouncedTerm(query, 600);

  const { loading: loader } = useQuery(PLATFORM_ATHLETES, {
    variables: {
      filter: {
        ...(debouncedSearch && { keyword: debouncedSearch }),
      },
      platformId: auth.platformId,
      page: page + 1,
      limit: limit,
      // ...(country.length > 0 && {
      //   nation: country?.map((item) => item),
      // }),
      // ...(sports.length > 0 && {
      //   sports: sports?.map((item) => item?.id),
      // }),
      // ...(club.length > 0 && {
      //   clubs: club?.map((item) => item?.id),
      // }),
      // ...(gender && {
      //   gender: gender,
      // }),
      // ...(listingDateRange[1] !== null && {
      //   ageFrom: fromAge,
      //   ageTo: toAge,
      // }),
    },
    onCompleted: (data) => {
      setUsersArray(data?.platformSpecificUsers?.users);
      setResultCount(data?.platformSpecificUsers?.total);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  const dateRangeLabel =
    listingDateRange[0] !== null && listingDateRange[1] !== null
      ? `${moment(listingDateRange[0])?.format("MMM Do YY")} - ${moment(
          listingDateRange[1]
        )?.format("MMM Do YY")}`
      : // : listingDateRange[0] !== null
        // ? moment(listingDateRange[0])?.format("MMM Do YY")
        "Date of birth";
  useEffect(() => {
    checkAndRedirect(auth?.platformId, auth?.user);
  }, [auth?.platformId]);
  if (loader) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Dashboard: Users</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 4,
        }}
      >
        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb" separator={"/"}>
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard"
                variant="subtitle2"
              >
                {t("Home")}
              </Link>
              <Link
                color="textPrimary"
                component={RouterLink}
                to={
                  location.pathname === "/dashboard/platform-users"
                    ? "/dashboard/platform-users"
                    : "/dashboard/users"
                }
                variant="subtitle2"
              >
                {t("Users")}
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Box sx={{ mt: 1 }}>
          <PlatformUserTable
            members={usersArray}
            query={query}
            limit={limit}
            page={page}
            total={resultCount}
            setQuery={setQuery}
            setPage={setpage}
            setLimit={setLimit}
            loading={loader}
            setGender={setGender}
            setSports={setSports}
            setCountry={setCountry}
            club={club}
            setClub={setClub}
            country={country}
            sports={sports}
            gender={gender}
            setListingDateRange={setListingDateRange}
            listingDateRange={listingDateRange}
            dateRangeLabel={dateRangeLabel}
          />
        </Box>
      </Box>
    </>
  );
};

export default PlatformUsers;
